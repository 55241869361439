import React from "react"
import { graphql } from "gatsby"
import { BlogPageQueryData, BlogTemplate } from "templates/Blog"

interface Props {
  data: BlogPageQueryData
}

const BlogPage = ({ data }: Props) => <BlogTemplate data={data} />

export default BlogPage

export const pageQuery = graphql`
  query {
    postsRemark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/^/[^/]+/[^/]+$/" } } }
    ) {
      ...PostForListFields
    }
    seriesPartsRemark: allMarkdownRemark(
      filter: { fields: { slug: { regex: "//.+/.+/.+/" } } }
    ) {
      ...SeriesPartForListFields
    }
  }
`
